import React from 'react';
import './App.css';
import Header from "./components/Header";
import Home from "./components/Home";
// @ts-ignore
import { Helmet } from "react-helmet";
function App() {
    return (
        <div className="App">
            <Helmet>
                <title>Stake Bean - Home</title>
            </Helmet>
            <Header/>
            <div className="Content">
                <Home/>
            </div>
        </div>
    );
}

export default App;
