import React from 'react';
import './Header.css';

function Header() {
    return (
        <header className="Header">
            <nav className="Header-nav">
                <a href="/"><img src='/img/Stakebean.svg' alt="logo"/></a>
                <ul className="Header-navList">
                    <li><a href="/stake">Stake</a></li>
                    {/*<li><a href="/network">Network</a></li>*/}
                    <li><a href="/service">Service</a></li>
                    <li><a href="/blog">Blog</a></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
