import React from 'react';
import './Home.css';

function Home() {
    return (
        <>
            <div className = 'Home'>
                <div className="area0_1">
                    <div className='loan_main'>Professionals In</div>
                    <div className='loan_main non-custodial'>Non-Custodial</div>
                    <div className='loan_main'>Stake Service Provider</div>
                    <hr/>
                    <div className= 'loan_pass'>Earn income by staking on Proof-of-Stake blockchains</div>
                </div>
                <div className="area0_2"></div>
            </div>
        </>
    );
}

export default Home;
